// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';

import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import { ModalPlugin, ToastPlugin } from 'bootstrap-vue';
import router from './router';
import * as config from './shared/config/config';
import * as bootstrapVueConfig from './shared/config/config-bootstrap-vue';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import InfiniteLoading from 'vue-infinite-loading';
import VueCal from 'vue-cal';
import PatientBrief from './usertasks/shared/patient-brief.vue';
import HealthService from './admin/health/health.service';
import MetricsService from './admin/metrics/metrics.service';
import LogsService from './admin/logs/logs.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
import LoginService from './account/login.service';
import AccountService from './account/account.service';
import AlertService from './shared/alert/alert.service';

import '../content/scss/global.scss';
import '../content/scss/vendor.scss';
import TranslationService from '@/locale/translation.service';
import PostboxService from '@/postbox/postbox.service';
import BpmProcessManagementService from '@/shared/data/bpm-process-management.service';
import BpmProcessVariableService from '@/entities/bpm-process-variable/bpm-process-variable.service';
import QuestionnaireService from '@/questionnaire/questionnaire.service';
import CustomerOverviewService from '@/customer-overview/customer-overview.service';
import CarerOverviewService from '@/carer-overview/carer-overview.service';
import CustomerDetailService from '@/customer-detail/customer-detail.service';
import CarerDetailService from '@/carer-detail/carer-detail.service';
import CarerService from '@/entities/carer/carer.service';
import LanguageSkillService from '@/entities/language-skill/language-skill.service';
import CommunicationService from '@/entities/communication/communication.service';
import RatingService from '@/entities/rating/rating.service';
import ContactService from '@/entities/contact/contact.service';
import BankAccountService from '@/entities/bank-account/bank-account.service';
import BpmUserTaskService from '@/entities/bpm-user-task/bpm-user-task.service';
import VueHtml2pdf from 'vue-html2pdf';
import CarerSuggestion2PdfComponent from '@/html2pdf-components/carer-suggestion/carer-suggestion-2-pdf.vue';
import Invoice2PdfComponent from '@/html2pdf-components/invoice/invoice-2-pdf.vue';
import WatcherComponent from '@/components/watcher/watcher.vue';
import FragebogenComponent from '@/components/fragebogen/fragebogen.vue';
/* tslint:disable */

// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here

/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
config.initFortAwesome(Vue);
bootstrapVueConfig.initBootstrapVue(Vue);
Vue.use(Vue2Filters);
Vue.use(ToastPlugin);
Vue.use(VueHtml2pdf);
Vue.use(ModalPlugin);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('jhi-item-count', JhiItemCountComponent);
Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
Vue.component('infinite-loading', InfiniteLoading);
Vue.component('vue-cal', VueCal);
Vue.component('patient-brief', PatientBrief);
Vue.component('vue-html-2-pdf', VueHtml2pdf);
Vue.component('carerSuggestion2Pdf', CarerSuggestion2PdfComponent);
Vue.component('invoice2Pdf', Invoice2PdfComponent);
Vue.component('watcherIdenticator', WatcherComponent);
Vue.component('fragebogen', FragebogenComponent);
const i18n = config.initI18N(Vue);
const store = config.initVueXStore(Vue);

const translationService = new TranslationService(store, i18n);
const loginService = new LoginService();
const accountService = new AccountService(store, translationService, (<any>Vue).cookie, router);

router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    next('/not-found');
  } else if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities).then(value => {
      if (!value) {
        sessionStorage.setItem('requested-url', to.fullPath);
        next('/forbidden');
      } else {
        next();
      }
    });
  } else {
    // no authorities, so just proceed
    next();
  }
});

/* tslint:disable */
new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  provide: {
    loginService: () => loginService,
    healthService: () => new HealthService(),
    configurationService: () => new ConfigurationService(),
    logsService: () => new LogsService(),
    metricsService: () => new MetricsService(),

    translationService: () => translationService,
    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    accountService: () => accountService,
    alertService: () => new AlertService(),
    bpmUserTaskService: () => new BpmUserTaskService(),
    bpmProcessVariableService: () => new BpmProcessVariableService(),
    postboxService: () => new PostboxService(),
    bpmProcessManagementService: () => new BpmProcessManagementService(),
    customerOverviewService: () => new CustomerOverviewService(),
    carerOverviewService: () => new CarerOverviewService(),
    customerDetailService: () => new CustomerDetailService(),
    carerDetailService: () => new CarerDetailService(),
    carerService: () => new CarerService(),
    languageSkillService: () => new LanguageSkillService(),
    communicationService: () => new CommunicationService(),
    ratingService: () => new RatingService(),
    contactService: () => new ContactService(),
    bankAccountService: () => new BankAccountService(),
    questionnaireService: () => new QuestionnaireService(),
  },
  i18n,
  store,
});

setupAxiosInterceptors(
  error => {
    const url = error.response?.config?.url;
    const status = error.status || error.response.status;
    if (status === 401) {
      // Store logged out state.
      store.commit('logout');
      if (!url.endsWith('api/account') && !url.endsWith('api/authenticate')) {
        // Ask for a new authentication
        window.location.reload();
        return;
      }
    }
    console.log('Unauthorized!');
    return Promise.reject(error);
  },
  error => {
    console.log('Server error!');
    return Promise.reject(error);
  }
);
