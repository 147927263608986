var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-navbar",
    {
      staticClass: "bg-dark",
      attrs: { "data-cy": "navbar", toggleable: "md", type: "dark" },
    },
    [
      _c(
        "b-navbar-brand",
        { staticClass: "logo", attrs: { "b-link": "", to: "/" } },
        [_c("span", { staticClass: "logo-img" })]
      ),
      _vm._v(" "),
      _c(
        "b-navbar-brand",
        { staticClass: "version", attrs: { "b-link": "", to: "/changelog" } },
        [
          _c("span", { staticClass: "navbar-version" }, [
            _vm._v(_vm._s(_vm.version)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-navbar-toggle",
        {
          staticClass: "jh-navbar-toggler d-lg-none",
          attrs: {
            "aria-expanded": "false",
            "aria-label": "Toggle navigation",
            "data-toggle": "collapse",
            href: "javascript:void(0);",
            right: "",
            target: "header-tabs",
          },
        },
        [_c("font-awesome-icon", { attrs: { icon: "bars" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { id: "header-tabs", "is-nav": "" } },
        [
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [
              _c("b-nav-item", { attrs: { exact: "", to: "/" } }, [
                _c(
                  "span",
                  [
                    _c("font-awesome-icon", { attrs: { icon: "home" } }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        domProps: {
                          textContent: _vm._s(_vm.$t("global.menu.home")),
                        },
                      },
                      [_vm._v("Home")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.authenticated
                ? _c("b-nav-item", { attrs: { to: "/postbox" } }, [
                    _c(
                      "span",
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "list-check" },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("global.menu.postbox")
                              ),
                            },
                          },
                          [_vm._v("Postbox")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      class: {
                        "router-link-active":
                          _vm.subIsActive("/customer-create"),
                      },
                      attrs: {
                        id: "new-customer-menu",
                        "active-class": "active",
                        right: "",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "user-plus" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.customerCreate.main")
                                ),
                              },
                            },
                            [_vm._v("New Customer")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "active-class": "active",
                            to: "/customer-create/withProcess",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "user-plus" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "global.menu.customerCreate.withProcess"
                                  )
                                ),
                              },
                            },
                            [_vm._v("New Customer With Process")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "active-class": "active",
                            to: "/customer-create",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "user-plus" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "global.menu.customerCreate.withoutProcess"
                                  )
                                ),
                              },
                            },
                            [_vm._v("New Customer Without Process")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "active-class": "active",
                            to: "/customer-create/onlineQuestionnaire",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "user-plus" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "global.menu.customerCreate.onlineQuestionnaire"
                                  )
                                ),
                              },
                            },
                            [_vm._v("Create Questionnaire")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated
                ? _c(
                    "b-nav-item",
                    { attrs: { to: { name: "CarerOverview" } } },
                    [
                      _c(
                        "span",
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "user-nurse" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.carer-overview")
                                ),
                              },
                            },
                            [_vm._v("Carer-Overview")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated
                ? _c(
                    "b-nav-item",
                    { attrs: { to: { name: "CustomerOverview" } } },
                    [
                      _c(
                        "span",
                        [
                          _c("font-awesome-icon", { attrs: { icon: "users" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.customer-overview")
                                ),
                              },
                            },
                            [_vm._v("Customer-Overview")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasAnyAuthority("ROLE_MANAGER") && _vm.authenticated
                ? _c(
                    "b-nav-item",
                    { attrs: { to: { name: "AgencyOverview" } } },
                    [
                      _c(
                        "span",
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "building" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.agency-overview")
                                ),
                              },
                            },
                            [_vm._v("Agency-Overview")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasAnyAuthority("ROLE_MANAGER") && _vm.authenticated
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      class: {
                        "router-link-active": _vm.subIsActive("/statistics"),
                      },
                      attrs: {
                        id: "statistic-menu",
                        "active-class": "active",
                        right: "",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "chart-pie" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.statistic.main")
                                ),
                              },
                            },
                            [_vm._v("Statistic")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "active-class": "active",
                            to: "/statistics/month-sales",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "euro-sign" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.statistic.monthSales")
                                ),
                              },
                            },
                            [_vm._v("Month Sales")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasAnyAuthority("ROLE_ADMIN")
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      attrs: {
                        id: "admin-menu",
                        "active-class": "active",
                        right: "",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "users-cog" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.tools")
                                ),
                              },
                            },
                            [_vm._v("Administration")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.authenticated
                        ? _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.scheduleCheckInvoices()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "list-check" },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("global.menu.check-invoices")
                                        ),
                                      },
                                    },
                                    [_vm._v("Check Invoices2")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.authenticated
                        ? _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.scheduleCheckInvoicePayments()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "list-check" },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(
                                            "global.menu.check-invoice-payments"
                                          )
                                        ),
                                      },
                                    },
                                    [_vm._v("Check Invoice Payments")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      attrs: {
                        id: "entity-menu",
                        "active-class": "active",
                        "data-cy": "entity",
                        right: "",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "th-list" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.main")
                                ),
                              },
                            },
                            [_vm._v("Entities")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("entities-menu"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      class: {
                        "router-link-active": _vm.subIsActive("/admin"),
                      },
                      attrs: {
                        id: "admin-menu",
                        "active-class": "active",
                        "data-cy": "adminMenu",
                        right: "",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "users-cog" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.admin.main")
                                ),
                              },
                            },
                            [_vm._v("Administration")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "active-class": "active",
                            to: "/admin/metrics",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "tachometer-alt" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.admin.metrics")
                                ),
                              },
                            },
                            [_vm._v("Metrics")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "active-class": "active",
                            to: "/admin/health",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "heart" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.admin.health")
                                ),
                              },
                            },
                            [_vm._v("Health")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "active-class": "active",
                            to: "/admin/configuration",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "cogs" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.admin.configuration")
                                ),
                              },
                            },
                            [_vm._v("Configuration")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "active-class": "active",
                            to: "/admin/logs",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "tasks" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.admin.logs")
                                ),
                              },
                            },
                            [_vm._v("Logs")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.openAPIEnabled
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                "active-class": "active",
                                to: "/admin/docs",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "book" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.admin.apidocs")
                                    ),
                                  },
                                },
                                [_vm._v("API")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.languages && Object.keys(_vm.languages).length > 1
                ? _c(
                    "b-nav-item-dropdown",
                    { attrs: { id: "languagesnavBarDropdown", right: "" } },
                    [
                      _c(
                        "span",
                        {
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "flag" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.language")
                                ),
                              },
                            },
                            [_vm._v("Language")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.languages, function (value, key) {
                        return _c(
                          "b-dropdown-item",
                          {
                            key: `lang-${key}`,
                            class: { active: _vm.isActiveLanguage(key) },
                            on: {
                              click: function ($event) {
                                return _vm.changeLanguage(key)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " + _vm._s(value.name) + "\n        "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated
                ? _c(
                    "b-nav-item",
                    { attrs: { to: { name: "NotificationOverview" } } },
                    [
                      _c(
                        "span",
                        {
                          attrs: {
                            title: _vm.$t("global.menu.notification-overview"),
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "bell" } }),
                          _vm._v(" "),
                          _c(
                            "b-badge",
                            {
                              attrs: {
                                variant:
                                  _vm.notificationCount > 0
                                    ? "primary"
                                    : "light",
                              },
                            },
                            [_vm._v(_vm._s(_vm.notificationCount))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "pointer",
                  class: { "router-link-active": _vm.subIsActive("/account") },
                  attrs: {
                    id: "account-menu",
                    "active-class": "active",
                    "data-cy": "accountMenu",
                    href: "javascript:void(0);",
                    right: "",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "navbar-dropdown-menu",
                      attrs: { slot: "button-content" },
                      slot: "button-content",
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "user" } }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "no-bold",
                          domProps: { textContent: _vm._s(_vm.username) },
                        },
                        [_vm._v(" Account ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.authenticated && _vm.hasAnyAuthority("ROLE_USER")
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            id: "user-settings",
                            "active-class": "active",
                            "data-cy": "user-settings",
                            to: "/account/settings/0",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "gear" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.settings")
                                ),
                              },
                            },
                            [_vm._v("Settings")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.authenticated
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            id: "logout",
                            "active-class": "active",
                            "data-cy": "logout",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.logout()
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "sign-out-alt" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.logout")
                                ),
                              },
                            },
                            [_vm._v("Sign out")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.authenticated
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            id: "login",
                            "active-class": "active",
                            "data-cy": "login",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openLogin()
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "sign-in-alt" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.login")
                                ),
                              },
                            },
                            [_vm._v("Sign in")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }